<template>
    <div v-if="!consentGiven" class="cookie-consent">
      <p>Nous utilisons des cookies pour améliorer votre expérience sur notre site. En poursuivant votre navigation, vous acceptez notre <a href="/privacy-policy" target="_blank">politique de confidentialité</a>.</p>
      <button class="btn btn-primary" @click="acceptCookies">Accepter</button>
      <button class="btn btn-secondary" @click="declineCookies">Refuser</button>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        consentGiven: false,
      };
    },
    mounted() {
      this.consentGiven = localStorage.getItem('cookieConsent') === 'true';
    },
    methods: {
      acceptCookies() {
        this.consentGiven = true;
        localStorage.setItem('cookieConsent', 'true');
      },
      declineCookies() {
        this.consentGiven = true;
        localStorage.setItem('cookieConsent', 'false');
        // Logique pour désactiver les cookies ou tout autre tracking
      },
    },
  };
  </script>
  
  <style scoped>
  .cookie-consent {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: var(--navbar-background);
    color: var(--text-color);
    padding: 15px;
    text-align: center;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.3);
    z-index: 1000;
  }
  
  .cookie-consent a {
    color: var(--primary-color);
    text-decoration: underline;
  }
  
  .cookie-consent .btn {
    margin-left: 10px;
  }
  </style>
  