<template>
  <div id="app">
    <nav>
      <div class="nav-content">
        <img src="@/assets/logo2.png" alt="logo Pizzeria Treviglio" class="logo">
        <div class="burger-menu" @click="toggleMenu">
          <i class="fas fa-bars"></i>
        </div>
        <ul :class="{ 'menu-open': menuOpen }">
          <li><router-link to="/" exact @click="closeMenu"><i class="fas fa-home"></i> Accueil</router-link></li>
          <li><router-link to="/menu" @click="closeMenu"><i class="fas fa-utensils"></i> Notre Carte</router-link></li>
          <li><router-link to="/about" @click="closeMenu"><i class="fas fa-info-circle"></i> À propos</router-link></li>
          <li><router-link to="/contact" @click="closeMenu"><i class="fas fa-envelope"></i> Contact</router-link></li>
        </ul>
      </div>
    </nav>
    <div class="content">
      <router-view/>
    </div>
    <CookieConsent />
    <footer class="footer">
      <div class="footer-info">
        <p>Rue Georges Emmanuel 3, 5020 Flawinne</p>
        <p>+32 81 570 790 | +32 493 762 927</p>
        <p>info@pizzeriatreviglio.be</p>
        <router-link to="/contact" @click="closeMenu" class="contact-link">
          <span class="footer-links">Besoin d'informations ? Contactez-nous !</span>
        </router-link>
        <div class="button-container mb-3">
          <button class="nav-button btn-sm" @click="openGoogleMaps"><i class="fas fa-map-marker-alt"></i> Google Maps</button>
        </div>
        <hr class="footer">
<!-- Bouton de partage Facebook -->
<div class="share-section">
  <p class="share-text">Partagez votre expérience avec vos amis sur Facebook !</p>
  <div class="fb-share-button mb-4"
       data-href="https://pizzeriatreviglio.be"
       data-layout="button"
       data-size="large">
  </div>
</div>
<hr class="footer">
      </div>
      <div class="footer-copyright">
    <p>&copy; 2024 Pizzeria Treviglio. Tous droits réservés.</p>
    <p>Made with <span class="heart">&hearts;</span> by <a href="https://cyberglow.be" target="_blank">CyberGlow</a></p>
  </div>
  <div class="footer-links">
    <router-link :to="{ name: 'PrivacyPolicyPage' }">Politique de Confidentialité | </router-link>
    <router-link :to="{ name: 'LegalNoticePage' }">Mentions Légales</router-link>
  </div>
</footer>

  </div>
</template>

<script>
import CookieConsent from './components/CookieConsent.vue';

export default {
  name: 'App',
  components: {
    CookieConsent,
  },
  data() {
    return {
      menuOpen: false,
    };
  },
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
    closeMenu() {
      this.menuOpen = false;
    },
    openGoogleMaps() {
      const lat = 50.46071484267182;
      const lng = 4.797822742580121;
      const url = `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`;
      window.open(url, '_blank');
    },
    mounted() {
  if (window.FB) {
    window.FB.XFBML.parse();  // Re-analyser les éléments FB
  }
},
  },
};
</script>

<style scoped>
@import './assets/custom-styles.css';

/* Footer Styles */
.footer {
  background-color: var(--primary-color);
  color: #fff;
  padding: 15px 10px;
  text-align: center;
  font-family: 'Merriweather', serif;
  display: flex;
  flex-direction: column; /* Tous les éléments sur une ligne verticale */
  justify-content: center; /* Centrage vertical */
  align-items: center; /* Centrage horizontal */
  gap: 20px; /* Espace entre les éléments */
  position: relative;
  padding-bottom: 10px; /* Espace pour les liens en bas */
}

.footer-info,
.footer-copyright,
.footer-links {
  width: 100%; /* Chaque section prend toute la largeur disponible */
}

.footer-info p {
  margin: 5px 0;
  font-size: 1.2rem;
}

.footer-copyright {
  font-size: 1rem;
}

.footer-copyright .heart {
  color: red;
}

.footer-copyright a {
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}

.footer-copyright a:hover {
  color: var(--secondary-color);
}

.footer-links {
  margin-top: 3px;
}

.footer-links a {
  color: #fff;
  text-decoration: none;
  font-size: 0.75rem;
}

.footer-links a:hover {
  color: var(--secondary-color);
}

.footer hr {
  width: 50%; /* Augmenter légèrement la largeur pour plus de présence */
  border: none;
  border-top: 2px solid rgba(255, 255, 255, 0.7); /* Une ligne plus visible et légèrement transparente */
  margin: 5px auto; /* Toujours centré avec un espacement agréable */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Effet d'ombre subtil pour donner de la profondeur */
  transition: width 0.3s ease; /* Animation douce lorsque la taille est modifiée */
}


.contact-link {
  color: #fff; /* Couleur blanche pour correspondre au footer */
  text-decoration: none;
  font-size: 1rem;
  font-weight: bolder;
  transition: color 0.3s ease; /* Transition douce pour le survol */
  margin-top: 10px;
}

.contact-link:hover {
  color: var(--secondary-color); /* Change la couleur lors du survol */
}

.footer-links i {
  margin-right: 5px;
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
  .footer {
    padding: 20px 10px;
  }

  .footer-info,
  .footer-copyright,
  .footer-links {
    text-align: center;
    margin-bottom: 10px;
  }
}

@media (max-width: 480px) {
  .footer {
    padding: 15px 10px;
  }

  .footer-copyright {
    font-size: 0.75rem;
  }
}
</style>
