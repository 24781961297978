import { createRouter, createWebHistory } from 'vue-router';

const HomePage = () => import('@/views/HomePage.vue');
const MenuPage = () => import('@/views/MenuPage.vue');
const ContactPage = () => import('@/views/ContactPage.vue');
const AboutPage = () => import('@/views/AboutPage.vue');
const PrivacyPolicyPage = () => import('@/views/PrivacyPolicyPage.vue');
const LegalNoticePage = () => import('@/views/LegalNoticePage.vue');

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage,
  },
  {
    path: '/menu',
    name: 'MenuPage',
    component: MenuPage,
  },
  {
    path: '/contact',
    name: 'ContactPage',
    component: ContactPage,
  },
  {
    path: '/about',
    name: 'AboutPage',
    component: AboutPage,
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicyPage',
    component: PrivacyPolicyPage,
  },
  {
    path: '/legal-notice',
    name: 'LegalNoticePage',
    component: LegalNoticePage,
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  // Ajout de la gestion du comportement de défilement
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition; // Si on revient en arrière, on restaure la position précédente
    } else {
      return { top: 0, behavior: 'smooth' }; // Remet à zéro l'overflow avec un effet smooth
    }
  },
});

export default router;
